
export default class Overlay {

    constructor() {
        this.overlayBox = document.querySelector('.overlay');
        if (this.overlayBox) {
            this.closeAnimationTime = 100;
            this.btnClose = document.querySelector('.btn-close');
            this.btnClose.addEventListener("click", this.close.bind(this));
        }
    }

    delegate(closeFunc = null){
        this.delegateFunc = closeFunc;
    }

    noscrollOverlay() {
        document.querySelector('html').classList.add('overlay-active');
    }
    openShowOverlay() {
        this.overlayBox.classList.add('show');
    }
    openContainerInOverlay() {
        this.overlayBox.classList.add('container__in');
    }
    openContentInOverlay() {
        this.overlayBox.classList.add('content__in');
    }

    close() {
        this.overlayBox.classList.remove('content__in');
        setTimeout(() => {
            this.overlayBox.classList.remove('container__in');
        }, 200);
        setTimeout(() => {
            this.overlayBox.classList.remove('show');
            if (this.delegateFunc) this.delegateFunc();
        }, 400);
        setTimeout(() => {
            document.querySelector('html').classList.remove('overlay-active');
        }, 600);
    }

}
