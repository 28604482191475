
/**
 * Oakridge JS
 */

import Swiper from 'swiper';
import anime from 'animejs';
import scrollMonitor from 'scrollmonitor';
import OakridgeIntro from './oakridge_intro';
import Tab from '../../modules/tab';
import Scrollto from '../../modules/scrollto';
import CustomBodymovin from '../../modules/bodymovin';
import LABM from '../../json/web_wave_green_white.json';
import TextFx from '../../vender/textfx';
import ajaxSubmit from '../../modules/mcAjaxSubmit';


export default class Oakridge {

  constructor() {
    this.body = document.querySelector('body');
    this.homecover = document.querySelector('.slideshow-wrap');
    this.showcase = document.querySelector('.showcase-intro-wrap');
    this.progressbar = document.querySelector('.slideshow-bar-progress');
    this.slideitems = [...document.querySelectorAll('.swiper-slide')];
    this.panel = document.querySelector('.panel-wrap');
    // this.ajaxNavs = [...document.querySelectorAll('[data-pageload]')];
    this.tabs = [...document.querySelectorAll('[data-tab]')];
    // this.hss = document.querySelector('.programs');
    this.time = 2; // [2]approx 6s
    this.bmLineAnimation = document.getElementById('bmLineAnimation');
    this.anime = new anime();
    this.titles = [...document.querySelectorAll('.title')];
    this.scrollNavs = [...document.querySelectorAll('[data-scroll-to]')];

    this.mcAjaxSubmit = new ajaxSubmit('mc_embed_signup');
    if (this.mcAjaxSubmit.container) this.mcAjaxSubmit.init('mc-embedded-subscribe-form');

    this.mcAjaxSubmitFooter = new ajaxSubmit('mc_embed_signup_footer');
    if (this.mcAjaxSubmitFooter.container) this.mcAjaxSubmitFooter.init('mc-embedded-subscribe-form-footer');

    this.events();
  }

	/**
	 * Event listeners
	 */
  events() {

    new OakridgeIntro();

    if (this.showcase) this.showcaseQuoteSwiper();

    this.setupLineAnimeBodyMovin();

    if (this.tabs) {
      this.tab = new Tab(this.tabs);
      this.tab.init();
    }
    
    if (this.scrollNavs) {
      this.st = new Scrollto();
      this.scrollNavs.forEach(link => link.addEventListener('click', this.scrollToHandle.bind(this)));
    }

    if (this.panel) this.panelinit();

  }

  scrollToHandle(e) {
    e.preventDefault();
    const nav = e.currentTarget;
    const name = nav.dataset.scrollTo;
    const selector = `[data-scroll-reach="${name}"]`;
    const content = document.querySelector(selector);

    this.st.animate(content.offsetTop, 600)
  }


  showcaseQuoteSwiper() {
    this.showcaseSwiper = new Swiper(this.showcase, {
      autoplay: {
        delay: 5000,
        waitForTransition: false,
        disableOnInteraction: false
      },
      speed: 2000,
      loop: true
    });
  }

  panelinit() {

    this.panelSwiper = new Swiper(this.panel, {
      speed: 1000,
      slidesPerView: 4,
      spaceBetween: 20,
      // slidesPerGroup: 4,
      loop: true,
      breakpoints: {
        1080: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        }
      },
      loopFillGroupWithBlank: true,
      navigation: {
        prevEl: '.panel-nav-left',
        nextEl: '.panel-nav-right',
      },
      autoplay: {
        delay: 3000,
        waitForTransition: false,
        disableOnInteraction: false
      },
    });
  }


  initSwiper() {
    const self = this;
    this.homeSwiper = new Swiper(this.homecover, {
      // speed: 1000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      navigation: {
        nextEl: '.slideshow-nav-left',
        prevEl: '.slideshow-nav-right',
      },
      on: {
        slideChange: function () {
          self.startProgressbar();
        },
      },
      // autoplay: {
      //   delay: 6000,
      //   waitForTransition: false,
      //   disableOnInteraction: false
      // },
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      //   // renderBullet: (index, className) => {
      //   //   return '<div class="' + className + '">' + this.homecoverNav[index].innerHTML + '</div>';
      //   // },
      // },
    });
    this.startProgressbar();
  }

  startProgressbar() {
    this.resetProgressbar();
    this.percentTime = 0;
    this.tick = setInterval(this.interval.bind(this), 30);
  }
  
  interval() {
    this.percentTime += 1 / (this.time + 0.1);
      this.progressbar.style.width = this.percentTime + "%";
      if (this.percentTime >= 100) {
        this.next();
        this.startProgressbar();
    }
    if (this.isPause === false) {

    }
  }

  resetProgressbar() {
    this.progressbar.style.width = 0 + "%";
    clearTimeout(this.tick);
  }

  next(){
    this.homeSwiper.slideNext();
  }

  setupLineAnimeBodyMovin() {
    this.lineAnimeBmObj = new CustomBodymovin({
      elm: this.bmLineAnimation,
      data: LABM,
      loop: true
    });
    this.lineAnimeBmObj.play();
    
  }
}
