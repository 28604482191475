import Scrollto from './scrollto';
import { viewport, scrollTop, getPosition } from '../helpers/helpers';
import logoBM from '../json/Goh_Logo_Animation_a.json';
import CustomBodymovin from './bodymovin';
import throttle from 'lodash.throttle';

export default class Header {

  constructor() {
    this.body = document.querySelector('body');
    this.container = document.querySelector('#header');
    this.main = document.querySelector('.main');
    this.navItems = document.querySelectorAll('#header nav a');
    this.bmLogo = document.getElementById('logoBM');
    this.mainIsInView = false;
    this.headerIsFixed = false;
    this.headerIsRemove = false;
    this.hamburger = document.querySelector('.navburger');
    this.btnPagetop = document.querySelector('.btn-pagetop');
    this.mobileNavIsOpen = false;
    this.stp = 0;
    this.st = new Scrollto();

    this.header = null;
    this.init();
  }

  init() {
    this.onScroll();
    this.scrollHandle = throttle(this.onScroll, 16).bind(this);
    window.addEventListener('scroll', this.scrollHandle);
    this.hamburger.addEventListener('click', this.hamburgerHandle.bind(this));
    this.btnPagetop.addEventListener('click', this.clickPagetop.bind(this));
    if (this.bmLogo) this.setupLogoBodyMovin();
  }

  onScroll(e) {
    const st = scrollTop();
    if (this.main) this.mainIsInView = st > 100;
    this.headerIsFixed = this.container.classList.contains('fixed');

    if (this.mainIsInView && !this.headerIsFixed) {
      this.container.classList.add('fixed');
    } else if (!this.mainIsInView && this.headerIsFixed) {
      this.container.classList.remove('fixed');
    }
    if (this.mainIsInView) {
      // console.log(this.stp, st);
      (st > this.stp) ? this.removeHeader() : this.defaultHeader();
      this.stp = scrollTop();
    }
  }

  defaultHeader() {
    if (this.headerIsRemove) {
      this.container.classList.remove('remove');
      this.headerIsRemove = false;
    }
  }
  removeHeader() {
    if (!this.headerIsRemove) {
      this.container.classList.add('remove');
      this.headerIsRemove = true;
    }
  }

  // mobile
  hamburgerHandle() {
    this.mobileNavIsOpen ? this.closeMobileNav() : this.openMobileNav();
  }
  openMobileNav() {
    this.body.classList.add('mobile-nav-open');
    this.mobileNavIsOpen = true;
  }
  closeMobileNav() {
    this.body.classList.remove('mobile-nav-open');
    this.mobileNavIsOpen = false;
  }

  //pagetop
  clickPagetop() {
    this.st.animate(0, 1000);
  }

  setupLogoBodyMovin() {
    this.bmLogo.classList.remove('init');
    this.logoBmObj = new CustomBodymovin({
      elm: this.bmLogo,
      data: logoBM
    });
    this.logoBmObj.play();
  }
}


