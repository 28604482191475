
/**
 * Oakridge Introduction JS
 */

import anime from 'animejs';
import scrollMonitor from 'scrollmonitor';
import TextFx from '../../vender/textfx';

export default class OakridgeIntro {

  constructor() {
    this.body = document.querySelector('body');
    this.slideshow = document.querySelector('.slideshow');
    this.revealEles = [...document.querySelectorAll(".reveal-item")];
    this.anime = new anime();
    this.titles = [...document.querySelectorAll('.title')];
    this.events();
  }

	/**
	 * Event listeners
	 */
  events() {

    // this.revealEles.forEach(ele => {

    //   var elementWatcher = scrollMonitor.create(ele, -100);
    //   elementWatcher.enterViewport(function () {
    //     if (this.watchItem.classList.contains('reveal-item')) {
    //       this.watchItem.classList.remove('reveal-item');
    //     }
    //   });
    // });

    this.slideshow.classList.remove('init');
    anime({
      targets: this.slideshow,
      duration: 1200,
      opacity: 1.0,
      complete: () => {
        setTimeout(() => {
          this.titles.forEach(title => {
            let text = new TextFx(title);
            this.effect = (title.hasAttribute('data-effect')) ? title.getAttribute('data-effect') : 'fxgoh';
            // this.el.getAttribute('data-effect');
            text.show(this.effect);
          });
        }, 50);

      }
    });


  }


}
