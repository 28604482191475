
// import OakridgeLocation from './oakridge/oakridge_location';
import ajaxSubmit from '../modules/mcAjaxSubmit';
import Scrollto from '../modules/scrollto';

export default class Subpage {

  constructor(pagebody) {
    this.body = document.querySelector('#ajaxOverlay');
    // this.header = document.querySelector('#header');
    // this.events();
    this.pagebody = pagebody;
    this.st = new Scrollto();
    this.contentJS = null;
    this.mcAjaxSubmit = new ajaxSubmit('mc_embed_signup');
    if (this.mcAjaxSubmit.container) this.mcAjaxSubmit.init('mc-embedded-subscribe-form');
  }

  init() {
    this.scrolls = [...this.pagebody.querySelectorAll('[data-scrollto]')];
    // if (this.pagebody.classList.contains('oakridge-location')) {
    //   this.contentJS = new OakridgeLocation(this.pagebody);
    // }
    if (this.scrolls){
      this.scrolls.forEach(link => link.addEventListener('click', this.scrollTo.bind(this)));
    }
    // window.addEventListener('load', this.onLoad.bind(this));
  }

  scrollTo(e) {
    e.preventDefault();
    let element = e.currentTarget;
    let name = element.dataset.scrollto;
    const selector = `#${name}`;
    const content = this.pagebody.querySelector(selector);
    this.st.animate(content.offsetTop, 1000, this.body);
  }

  end(pagebody) {
    if (this.contentJS) {
      this.contentJS.end();
    }
  }

}
