// Accordion //

export default class Accordion {

    constructor(acc) {
        this.accs = acc;
        this.isCloseAll = acc.getAttribute('data-acc-close');
        this.isInitOn = acc.hasAttribute('data-acc-init');
        this.navItems = null;
        this.contentItems = null;
    }

    init(){
        this.registerEvents();
    }

    registerEvents() {
        this.accSetting(this.accs);
        if (this.isCloseAll) document.addEventListener("click", this.closeAll.bind(this));
    }

    accSetting(acc) {
        this.navItems = [...acc.querySelectorAll('[data-acc-tab]')];
        this.contentItems = [...acc.querySelectorAll('[data-acc-item]')];
        this.navItems.map(function (navItem, i) {
            navItem.addEventListener("click", (e) => this.accClickHandler(e, i, acc));
        }, this);
        // init
        if (this.isInitOn) {
            const nav = acc.querySelector('[data-acc-tab]');
            const content = acc.querySelector('[data-acc-item]');
            this.setShowContent(nav, content, acc, 1);
        }
    }

    accClickHandler(e, i, parent) {
        e.preventDefault();
        const nav = e.currentTarget;
        if (this.isCloseAll) {
            e.stopPropagation();
            if (parent.pnav === nav) {
            } else {
                this.closeAll();
            }
        }
        const content = nav.nextElementSibling;
        this.setShowContent(nav, content, parent, true);
    }

    setShowContent(nav, content, parent, init = false) {
        if (!init) return false;
        content.classList.toggle('active');
        nav.classList.toggle('active');
        parent.pnav = nav;
    }
   
    closeAll(e = null) {
        this.navItems.forEach(nav => nav.classList.remove('active'));
        this.contentItems.forEach(item => item.classList.remove('active'));
    }
}

