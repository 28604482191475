'use strict';

import 'babel-polyfill';
import scrollMonitor from 'scrollmonitor';
import Oakridge from './pages/oakridge/oakridge';
import Accordion from './modules/accordion';
import Home from './pages/home';
import Header from './modules/header';
import FeatureNews from './modules/featurenews';
import AjaxLoadPage from './modules/ajaxLoadPage';
import LoadingBM from './json/goh_loading_v02.json';
import CustomBodymovin from './modules/bodymovin';

// import ajaxSubmit from './modules/mcAjaxSubmit';

class App {

  constructor() {
    this.body = document.querySelector('body');
    this.header = document.querySelector('#header');
    this.loading = document.getElementById('loading');
    this.bmLoading = document.getElementById('loadingBM');
    this.revealEles = [...document.querySelectorAll(".reveal-item")];
    this.accs = [...document.querySelectorAll('[data-acc]')];
    this.events();
  }

  events() {
    this.setupLoadingBodyMovin();
    
    this.featurenews = new FeatureNews();
    if (this.featurenews.container) this.featurenews.init();

    window.addEventListener('load', this.onLoad.bind(this));
  }

  setupLoadingBodyMovin() {
    this.loadingBmObj = new CustomBodymovin({
      elm: this.bmLoading,
      data: LoadingBM,
      // loop: true
      onComplete: () => {
        this.loadingBmObj.goToAndPlay(600);
      }
    });
    this.loadingBmObj.play();
  }

  onLoad() {
    setTimeout(() => {
      this.loading.classList.add('complete');
    }, 300);
    setTimeout(() => {
      this.contentInit();
    }, 1000);
  }

  contentInit(){
    if (this.body.classList.contains('page-template-page-oakridge') || this.body.classList.contains('page-template-page-oakridge-ch')) {
      this.oakridge = new Oakridge();
    }
    // if (this.body.classList.contains('page-template-page-home') || this.body.classList.contains('page-template-page-oakridge-ch')) {
    //   this.home = new Home();
    // }
    if (this.header) {
      this.header = new Header();
    }

    if (this.accs) {
      this.accs.forEach(acc => {
        let accObj = new Accordion(acc);
        accObj.init();
      });
    }

    this.alp = new AjaxLoadPage();
    if (this.alp.ajaxlinks) this.alp.init();

    this.revealEles.forEach(ele => {
      var elementWatcher = scrollMonitor.create(ele, -100);
      elementWatcher.enterViewport(function () {
        if (this.watchItem.classList.contains('reveal-item')) {
          this.watchItem.classList.remove('reveal-item');
        }
      });
    });
  }

}

window.App = new App();