
/**
 * Home JS
 */

import Swiper from 'swiper';
import CustomBodymovin from '../modules/bodymovin';
import LABM from '../json/web_wave.json';

export default class Home {

  constructor() {
    this.body = document.querySelector('body');
    this.homecover = document.querySelector('.slideshow-wrap');
    this.slideshow = document.querySelector('.slideshow');
    this.slideitems = [...document.querySelectorAll('.swiper-slide')];
    this.homecoverNav = [...document.querySelectorAll('.slideshow-navss__item')];
    this.bmLineAnimation = document.getElementById('bmLineAnimation');
    this.events();
  }

	/**
	 * Event listeners
	 */
  events() {
    if (this.slideitems.length > 1) this.initSwiper();
  }

  initSwiper() {
    const self = this;
    this.homeSwiper = new Swiper(this.homecover, {
      speed: 1000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      navigation: {
        nextEl: '.slideshow-nav-left',
        prevEl: '.slideshow-nav-right',
      },
      autoplay: {
        delay: 6000,
        waitForTransition: false,
        disableOnInteraction: false
      },
    });
  }

  setupLineAnimeBodyMovin() {
    this.lineAnimeBmObj = new CustomBodymovin({
      elm: this.bmLineAnimation,
      data: LABM,
      loop: true
    });
    this.lineAnimeBmObj.play();
  }
}
